.user-icon {
    display: inline-block;
    border-radius: 50%;
    height: 2.25rem;
    width: 2.25rem;
    line-height: 2.25rem;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-right: 0.25rem;
    background-color: #7f7fff;
    color: white;
    font-weight: bold;
}