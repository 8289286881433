body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
}

h3 {
  font-size: 1.1rem !important;
  color: #343A3E !important;
  font-weight: normal !important;
}

h6 {
  font-size: 1rem !important;
  color: #343A3E !important;
  font-weight: normal !important;
}