#loading {
    border: 4px solid var(--main-border-color);
    background-color: #FFFFFF;
    padding: 30px 50px;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 400;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    height: 100px;
    text-align: center;
}

.input-group {
    flex-wrap: nowrap !important;
}

.input-group > .Select > div:first-of-type {
    height: 3rem;
    width: 200px;
    border-color: var(--main-border-color) !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group > .Select > .Select-menu-outer {
    z-index: 20;
}

.btn {
    height: 100%;
}

.btn-group {
    height: 100%;
}

hr {
    border-color: var(--main-border-color);
    margin-top: 8px;
    margin-bottom: 8px;
}

.react-switch-bg {
    border: 1px solid var(--main-border-color) !important;
}

.react-switch-handle {
    border: 1px solid var(--main-border-color) !important;
}

.dashboard-options {
    border-bottom: 1px solid var(--main-border-color);
    /*background-color: #F6F9FA;*/
}

#dashboard {
    border: 1px solid var(--main-border-color);
    margin: 1rem;
    background-color: #FFFFFF;
    border-radius: 8px;
    overflow:hidden;
}