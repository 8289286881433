.tabs {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.tabs .tab {
  flex: 0 0 220px;
  height: 100px;
  border-right: 1px solid var(--main-border-color);
  border-bottom: 1px solid var(--main-border-color);
  background-color: #fbfbfb;
  padding: 1rem 2rem;
  vertical-align: middle;
  align-items: center;
  /*justify-content: center;*/
  display: flex;
  color: #147482;
}

.tabs .tab.active {
  border-bottom: 1px solid #ffffff;
  background-color: #ffffff;
  color: #1c0a36;
}

.tabs .tab-filler {
  flex-grow: 1;
  border-bottom: 1px solid var(--main-border-color);
  background-color: #fbfbfb;
}