#root .navbar {
    background-color: #ffffff !important;
    color: red !important;
    border-bottom: 1px solid var(--main-border-color);
}

#root .navbar .container-fluid {
    padding: 0;
}

#root .navbar-dark .navbar-nav .nav-link {
    color: #B4BFD4;
    cursor: pointer;
    padding: .5rem 1rem;
}

#root .navbar {
    padding: 0 1rem;
}

#root .navbar a {
    color: #9FA9BA;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    min-height: 4.4rem;
    align-items: center;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
}

#root .navbar a {
    border-bottom: 2px solid white;
}

#root .navbar a.active {
    border-bottom: 2px solid #5577ee;
}

#root .dropdown.nav-item {
    display: inline-block;
}

#root .navbar li {
    list-style-type: none;
}

#root .navbar .navbar-brand {
    font-size: 1rem;
    white-space: nowrap;
    min-width: 250px;
    border-right: 1px solid var(--main-border-color);
    margin: 0 1rem 0 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
}

#root .navbar .navbar-brand.hide-border {
    border-right: none;
}

#root .navbar .count {
    display: inline-block;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-radius: 8px;
    height: 1.5rem;
    font-size: 0.9rem;
    line-height: 1.5rem;
    text-align: center;
    margin: -1rem 0 -1rem 0.5rem;
    background-color: #8a8aff;
    color: #ffffff;
    font-weight: bold;
}