#clients {
    margin-top: 0;
    max-width: 100%;
    border-bottom: none !important;
    margin-bottom: 2rem;
}

#clients td {
    vertical-align: middle;
    cursor: pointer;
}

#clients tr:first-of-type td {
    border-top: none !important;
}

#clients p {
    font-size: 1.25rem;
    margin-bottom: 0;
}

#clients small {
    font-size: 0.9rem;
    color: #9198A9;
}

#clients td.logo {
    width: 1px;
}

#clients.table td.chart {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    width: 1px;
}

#clients tbody {
    border: 1px solid var(--main-border-color);
    border-left: 0;
    border-right: 0;
}

#clients td.logo > div {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: -1rem;
    margin-top: -1rem;
}

#clients .logo img {
    height: 100%;
}

#clients thead th {
    text-align: center;
    font-size: 13px;
    /*border-top: 1px solid var(--main-border-color) !important;*/
    border-top: none !important;
    border-left: 1px solid var(--main-border-color) !important;
    padding: 1.5rem !important;
}

#clients thead th.hidden {
    border-left: none !important;
}

#clients .border-left {
    border-color: var(--main-border-color) !important;
}

#clients .border-right {
    border-color: var(--main-border-color) !important;
}

#clients thead th.hidden:last-of-type {
}

#home {
    border: 1px solid var(--main-border-color);
    margin: 1rem;
    background-color: #FFFFFF;
    border-radius: 8px;
}
