.legal-terms {
  color: #111111;
}

.legal-terms .col {
  background-color: #ffffff;
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding: 2rem;
  border: 1px solid #D5E4EF;
  border-radius: 5px;
}

.legal-terms strong {
  color: #000000 !important;
  font-weight: bold;
}

.legal-terms h2 {
  font-size: 2rem;
  color: #000000 !important;
  margin-bottom: 1rem;
}

.legal-terms h3 {
  margin-top: 1.5rem;
  font-size: 1.5rem !important;
  color: #000000 !important;
}

.legal-terms h4 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #000000 !important;
}

.legal-terms a {
  color: deepskyblue;
}
