td.dimension:hover .drilldown .btn {
    opacity: 1;
}

td.dimension .drilldown .btn {
    opacity: 0;
}

td.dimension .drilldown {
    display: inline-block;
}

td.dimension .drilldown .btn {
    margin: 0;
    padding: 0;
    line-height: normal;
}

td.dimension .drilldown .btn-link {
    color: #666666 !important;
}

.drilldown {
    cursor: pointer;
}

.drilldown .fa {
    margin-left: 5px;
    position: relative;
    top: -2px;
}