#settings .container-fluid {
    padding:1rem;
}

#settings .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
}

#settings .section-title {
    color: #162142;
    font-size: 1.25rem;
}

#settings h3 {
    font-size: 1rem;
    font-weight: bolder;
    margin-bottom: 1rem;
}

#settings hr {
    margin: 1rem 0.4rem 2rem;
}

#settings .info {
    padding: 1rem 1.5rem;
    color: #888888;
}

#settings .info h3 {
    color: #000;
}

#settings .Select {
    width: 100%;
}

.btn-secondary {
    background-color: #ebeef2;
    border-color: #ebeef2;
    color: #444444;
}

.btn-secondary:hover {
    background-color: #dadde1;
    border-color: #dadde1;
    color: #444444;
}

#settings .tag-value {
    display: inline-block;
    background-color: #FBFCFD;
    color: #899ABE;
    border: 1px solid #D5E4EF;
    padding: 0.4rem 0.75rem;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
}