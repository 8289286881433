.stats {
    border-bottom: 1px solid var(--main-border-color);
    padding-top: 8px;
    padding-bottom: 8px;
}

.stats .stat {
    display: inline-block;
    font-size: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0.5rem 0;
    border-left: 1px solid var(--main-border-color);
    text-align: center;
}

.stats .stat:first-of-type {
    border-left: none;
}

.stats .stat p {
    color: #9198A9;
    font-size: 0.9rem;
    margin-bottom: 0;
}

#stats-card .card-body {
    padding: 1rem 0.5rem;
}