#recommendations table {
    width: 100%;
}

#recommendations .campaign {
    width: 25%;
    color: #4b4b94;
    font-size: 87.5%;
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

#recommendations .adgroup {
    width: 25%;
    color: #4b4b94;
    font-size: 87.5%;
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

#recommendations .recommendation pre {
    display: inline;
    color: #4b4b94;
}

#recommendations .recommendation strong {
    font-weight: 600;
}