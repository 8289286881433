#login-bg {
  position: fixed;
  background: linear-gradient(45deg, #5f5fdf, #7f7fff) !important;
  width: 100%;
  height: 100%;
  display: table;
}

#login-bg .wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 5rem 1rem 15rem;
}

#login-bg img {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  display: block;
  height: 26px;
}

#login-bg .card {
  margin: auto;
  max-width: 500px;
}

h5.card-header {
  padding: 0 2rem;
  background-color: #ffffff;
}

h5.card-header .tab {
  display: inline-block;
  border-bottom: 2px solid #5577ee;
  background-color: #ffffff;
  padding: 1.5rem 2rem 1.2rem 2rem;
  text-transform: uppercase;
  font-size: 1rem;
  color: #5577ee;
}

#login-form {
}

#login-form .form-group {
  padding: 0.5rem 0.8rem 0;
}

#login-form input {
  font-size: 1.1rem;
  padding: 0.8rem;
}

#login-form hr {
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
}

#login-form ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc;
}
