:root {
    --main-border-color: #D3D9E5;
    --secondary-bg-color: #F6F7FA;
}

html {
    font-size: 13px;
}

body {
    font-weight: 100 !important;
    color: #343A3E !important;
    background-color: #EEF4F6;
}

#root .btn-secondary {
    color: #343A3E;
    background-color: var(--secondary-bg-color);
    border-color: var(--main-border-color);
}

#root .btn-secondary.disabled, .btn-secondary:disabled {
    color: #343A3E;
    background-color: var(--secondary-bg-color);
    border-color: var(--main-border-color);
}

.btn {
    line-height: 2 !important;
    padding-left: 13px !important;
    padding-right: 13px !important;
}

.btn-link {
    font-weight: 400;
    color: #000000 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: transparent;
}

.btn-link:hover {
    text-decoration: none !important;
}

.card {
    margin-bottom: 1rem;
    border-color: var(--main-border-color);
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.form-inline {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.input-group {
    width: auto;
}

.form-inline > * {
    margin: 3px 3px;
}

/* Overriding Toast Notification Styling */

.redux-toastr .toastr {
    min-height: 50px;
}

.redux-toastr .toastr .rrt-middle-container {
    font-family: 'Open Sans', sans-serif !important;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
    width: 50px;
}

.redux-toastr .toastr .rrt-middle-container {
    margin-left: 55px;
    width: 70%;
}

.redux-toastr .toastr .rrt-middle-container .rrt-title {
    font-weight: normal !important;
    margin-bottom: 0;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
    height: auto;
}