.input-group-text {
    padding: 0.8rem 0.8rem !important;
    text-transform: uppercase;
    font-size: 0.8rem !important;
    color: #899ABE !important;
    background-color: var(--secondary-bg-color) !important;
    border-color: var(--main-border-color) !important;
}

.form-control {
    border-color: var(--main-border-color) !important;
    height: auto !important;
}
