.popover.w500 {
  width: 500px !important;
  max-width: 500px !important;
}

.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
  top: -.5rem !important; /* Not sure why I need to do this to correctly place arrow at top of popover? */
}

#Popover1 span {
  color: #899ABE !important;
}

#Popover2 span {
  color: #899ABE !important;
}

#Popover3 span {
  color: #899ABE !important;
}