#json-schema-dialog .checkboxes {
    border: 1px solid #ced4da;
    border-radius: 3px;
    padding: 5px 10px;
    max-height: 200px;
    overflow-y: scroll;
}

#json-schema-dialog .field .required {
    margin-left: 2px;
    color: red;
}

#json-schema-dialog .field-description {
    color: #888888;
}

#json-schema-dialog .checkboxes .checkbox input {
    margin-right: 10px;
}
