table.table {
    margin-top: 3px;
    width: -webkit-max-content; /* TODO: Not a lot of support for this - find alternative */
    min-width: 100%;
    max-width: none;
    border-bottom: 1px solid var(--main-border-color) !important;
    margin-bottom: 1px;
}

.table th {
    /*color: #899ABE;*/
    color: #697789;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 1rem 1.5rem !important;
    border-top: 1px solid var(--main-border-color) !important;
}

.table td {
    border-top: 1px solid var(--main-border-color) !important;
    /*color: #3A3D41;*/
    padding: 1.3rem 1.5rem !important;
    max-width: 400px;
}

.table thead th {
    border-bottom: 1px solid var(--main-border-color) !important;
}

.table>:not(:first-child) {
    border-top: none;
}

td.metric {
    text-align: right;
    position: relative;
}

td.metric span {
    z-index: 200;
    display: block;
    position: relative;
}

td.metric.first,th.metric.first {
    border-left: 1px solid var(--main-border-color) !important;
}

tfoot td {
    font-weight: 600;
    background-color: var(--secondary-bg-color) !important;
}

td.dimension > span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

td.dimension span.dimension-value:hover {
    border-bottom: 1px dashed #899ABE;
    cursor: pointer;
}

.loading-overlay {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    opacity: 0.7;
    text-align: center;
    padding-top: 100px;
    font-size: 24px;
}

.sort-icon {
    font-size: 1rem;
    cursor: pointer;
    padding-left: 10px;
    float:right;
}

.sort-icon .fa {
    font-size: 1rem;
}

.pagination {
    justify-content: center;
}

.table .data-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 0;
    min-width: 8px;
}

#snapshot-button {
    height: auto;
    font-size: 20px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

#tools {
    z-index: 10;
    position: fixed;
    bottom: 10px;
    right: 10px;
}

#pivot-table-wrapper {
    position: relative;
}