.recharts-tooltip-wrapper {
  z-index: 100;
}

.tooltip-content {
  margin: 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
}

.tooltip-content > div {
  margin-bottom: 0.15rem;
}

.tooltip-content ul {
  list-style-type: none;
  padding-left: 0.5rem;
}

.tooltip-content .history {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}