.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
}

.table-pagination .Select {
  width: auto !important;
}

.table-pagination .Select-control {
  border-color: var(--main-border-color) !important;
}