#chart-settings-button {
    cursor: pointer;
    color: #9198A9;
    font-size: 1.25rem;
    border: none;
    background: none;
    padding: 0;
    margin: 5px 0 0;
}

.chart-settings-popover {
    width: 200px;
}

.chart-settings-popover label {
    color: #9198A9;
}