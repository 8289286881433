#users .container-fluid {
    padding:1.25rem;
}

#users .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
}

#users .section-title {
    color: #162142;
    font-size: 1.25rem;
}

#users h3 {
    font-size: 1rem;
    font-weight: bolder;
    margin-bottom: 1rem;
}

#users hr {
    margin: 1rem 0.4rem 2rem;
}

#users .info {
    padding: 1rem 1.5rem;
    color: #888888;
}

#users .info h3 {
    color: #000;
}

.btn-secondary {
    background-color: #ebeef2;
    border-color: #ebeef2;
    color: #444444;
}

.btn-secondary:hover {
    background-color: #dadde1;
    border-color: #dadde1;
    color: #444444;
}
