.column-list {
    display: flex;
    flex-flow: row nowrap;
    font-size: 1rem;
    margin: -1rem;
}

.column-list .available-columns {
    position: absolute;
    top: 0;
    margin-top: 55px;
    overflow-y: scroll;
    height: 445px;
    width: 100%;
}

.column-list .available-item {
    margin-left: 20px;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.column-list .available-columns .fa-plus-circle {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: var(--main-border-color);
}

.modal-dialog {
    transform: none !important;
}

.sortable-list-item {
    z-index: 2000;
    user-select: none;
    padding: 0.4rem;
    margin: 0 0 5px 0;
    border: 1px solid var(--main-border-color);
    border-radius: 3px;
    font-size: 1rem;
    color: #899ABE;
    background-color: var(--secondary-bg-color);
    cursor: ns-resize;
}

.sortable-list-item .fa-grip-lines {
    font-size: 1.0rem;
    margin-right: 0.8rem;
    margin-left: 0.5rem;
    opacity: 0.5;
}

.sortable-list-item .fa-times {
    opacity: 0.5;
    margin-right: 4px;
    margin-top: 3px;
    pointer-events: none;
}

.sortable-list-item button {
    display: none;
    border: 0;
    margin-top: -4px;
    margin-right: -2px;
    float: right;
    background: none;
    color: #899ABE;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
}

.sortable-list-item:hover button {
    display: block;
}

.column-list .form-check {
    margin: 5px 0 5px 5px;
}

.column-list .form-check-label {
    margin-left: 5px;
}

.column-list h5 {
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: var(--secondary-bg-color);
    color: #899ABE;
    border-top: 1px solid var(--main-border-color);
    border-bottom: 1px solid var(--main-border-color);
    padding: 0.5rem 0.5rem 0.5rem 15px;
    z-index: 2000;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.column-list h4 {
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #fafafa;
    color: #899ABE;
    border-top: 0;
    border-bottom: 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
    z-index: 2000;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.column-list .none-found {
    font-style: italic;
    color: #777;
    margin-left: 15px;
}

.column-list .search-input {
    padding-top: 15px;
    height: 3rem;
    margin-left: 15px;
}

#left-column {
    width: 60%;
    height: 500px;
    max-height: 500px;
    position: relative;
    padding-right: 1rem;
}

#right-column {
    width: 40%;
    height: 500px;
    max-height: 500px;
    background-color: #fafafa;
    border-left: 1px solid var(--main-border-color);
    overflow-y: scroll;
}